import { Component, OnInit } from '@angular/core';
import { SessionService } from '../../service/session/session.service';

@Component({
  selector: 'app-home-tours',
  templateUrl: './home-tours.component.html',
  styleUrls: ['./home-tours.component.css']
})
export class HomeToursComponent implements OnInit {
  bannerMin = {
    max: 200,
    page: 1,
  }
  banners = [];

  tours = [];
  tourMin = {
    max: 20,
    page: 1,
    perPage:0,
    currentPage:0,
    total:0,
    type:'Español',
    start_number:0,
    category:'',
    tags:'',
    filter: {
      name: "",
      value: ""
    },
  }

  categoryMin = {
    max: 200,
    page: 1,
  }
  categories = [];

  starts = [
    {
      value:1,
      name:'⭑',
    },{
      value:2,
      name:'⭑⭑',
    },{
      value:3,
      name:'⭑⭑⭑',
    },{
      value:4,
      name:'⭑⭑⭑⭑',
    },{
      value:5,
      name:'⭑⭑⭑⭑⭑',
    }

  ]

  constructor(public session:SessionService) {
    // this.tourMin.type = this.session.getLanguage();
    // if(this.tourMin.type == null){
    //   this.tourMin.type = "Español";
    // }
   }

  ngOnInit(): void {
    this.getBanners();
    this.getHotels();
    this.getcategorys();
  }
  /**función para obtener el listado de banners
  */
  getBanners(){
    this.session.postRequest("banner:list",this.bannerMin).subscribe((data:any)=>{
      this.banners = data.object.instanceList;
    },error=>{
      console.log("error:banner:list",error);
    })
  }

  getHotels() {
    this.session.postRequest("hotels:filteringAll", this.tourMin).subscribe((data: any) => {
      this.tours = this.tours.concat(data.object.instanceList);
      this.tourMin.currentPage = data.object.currentPage;
      this.tourMin.perPage = data.object.perPage;
      this.tourMin.total = data.object.total;
    }, error => {
      console.log("error", error);
    })
  }

  getcategorys(){
    this.session.postRequest("category:list",this.categoryMin).subscribe((data:any)=>{
      this.categories = data.object.instanceList;
    },error=>{
      console.log("error:category:list",error);
    })
  }

  changeFilter(){
    this.tourMin.page = 1;
    this.tours = [];
    this.getHotels();
  }

  moreTours(){
    this.tourMin.page++;
    this.getHotels();
  }

}
