<app-home-menu></app-home-menu>
<div class="row">
  <div class="col-12">
    <ngb-carousel>
      <ng-template ngbSlide *ngFor="let banner of banners">
        <div class="picsum-img-wrapper">
          <img [src]="banner.image" alt="Random first slide" width="100%" style="height:30rem;object-fit:cover;">
        </div>
        <div class="carousel-caption ">
          <p class="text-dark font-robo mt-5 pt-5">Vive <span class="text-success">la mejor aventura </span>en un lugar mágico</p>
          <p class="text-primary mt-3 font-vuja">¡Atrévete a vivirlo!</p>
        </div>
      </ng-template>
    </ngb-carousel>
  </div>
</div>
<div class="row justify-content-center pt-3" style="background-color:#f3f3f3;">
  <div class="col-11">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a routerLink="/home">Inicio</a></li>
        <li class="breadcrumb-item"><a routerLink="/home/tours">Nuestros Tours</a></li>
        <li class="breadcrumb-item active" aria-current="page"  routerLink="/home/toursdetails/{{object.id}}">Tours</li>
      </ol>
    </nav>
  </div>
  <div class="col-11">
    <ngx-gallery [options]="galleryOptions" [images]="galleryImages" class="ngx-gallery"></ngx-gallery>
  </div>
  <div class="col-11">
    <ul ngbNav #nav="ngbNav" [(activeId)]="metadata.activeTab"  class="nav-tabs" (activeIdChange)="changeTab($event);">
      <li *ngFor="let object of tours; let i = index;" [ngbNavItem]="i">
        <a ngbNavLink>{{object.name}}</a>
        <ng-template ngbNavContent>
          <div class="cardTAB">
            <pre [innerHtml]="object.review" style="font-family: 'Roboto'; font-size: 11pt;"></pre>
            <pre [innerHtml]="object.description" style="font-family: 'Roboto'; font-size: 11pt;"></pre>
            <h3 *ngIf="amenitiesList.length > 0">Incluye</h3>
            <div class="amenities">
              <div class="" *ngFor="let am  of amenitiesList">
                <div class="ameniti-item"  *ngIf="am.selected">
                  <div>
                    <img [src]="am.icon" alt="{{am.name}}">
                    <span>{{am.name}}</span>
                  </div>
                </div>
              </div>
            </div>
            <h3 *ngIf="notincludeAttachementList.length > 0">No incluye</h3>
            <div class="amenities">
              <div class="" *ngFor="let am  of notincludeAttachementList">
                <div class="ameniti-item"  *ngIf="am.selected">
                  <div>
                    <img [src]="am.icon" alt="{{am.name}}">
                    <span>{{am.name}}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="contact">
              <button mat-stroked-button class="mr-2 mb-2" *ngFor="let file of attachementList" (click)="downLoadFile(file);">{{file.nameFile}} <mat-icon>file_download</mat-icon></button>

              <p><strong>Email</strong> {{object.email}}</p>
              <p><strong>Teléfono</strong> {{object.phone}}</p>
            </div>
            <div class="contactanos">
              <a routerLink="/home/contact">No dude en contactarnos para solicitar una cotización de algunos de nuestros tours</a>
            </div>
          </div>
          <span class="categoryStatus">{{object.category}}</span>

        </ng-template>
      </li>
    </ul>

<div [ngbNavOutlet]="nav" class="mt-2"></div>
  </div>
</div>
<app-home-footer></app-home-footer>
