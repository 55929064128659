import { environment } from '../../../environments/environment';
/**url / endpoint para la conexión al backend
* *Si environment.production es true devuelve url de "url_de_producción"
* *Si environment.production es false devuelve url de "url_de_desarrollo"
*
*/
// const server_url:string = environment.production ? 'http://encuestas.observatoriopueblosmagicosoax.com/public/api/':'http://encuestas.observatoriopueblosmagicosoax.com/public/api/';

const server_url:string = environment.production ? 'https://tours.referencetours.com/public/api/':'https://tours.referencetours.com/public/api/';


export default server_url;
