<nav class="navbar navbar-expand-lg navbar-light bg-lightn row pt-0 pb-0" style="background:#ebebeb;">
  <a class="navbar-brand col-3" href="#">
    <img src="/assets/img/home/logo.png" width="130" height="60" class="d-inline-block align-top" alt="">
  </a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarTogglerDemo02">
    <div class="text-end">
      <ul class="navbar-nav mt-2 mt-lg-0 ">
        <li class="nav-item menu">
          <a class="nav-link" routerLink="/admin">Inicio</a>
        </li>
        <li class="nav-item menu">
          <a class="nav-link" routerLink="/admin/tour">Tours</a>
        </li>
        <li class="nav-item menu">
          <a class="nav-link" routerLink="/admin/banner">Banners</a>
        </li>
        <li class="nav-item menu">
          <a class="nav-link" routerLink="/admin/company">Contenido Principal</a>
        </li>
        <li class="nav-item menu">
          <a class="nav-link"  [matMenuTriggerFor]="menu" style="cursor:pointer;">Configuracion</a>
        </li>
        <li class="nav-item active menu">
          <a class="nav-link" (click)="logout();" style="cursor:pointer;">Salir</a>
        </li>
        <!-- <li class="nav-item">
          <select class="form-control " [(ngModel)]="metadata.language" (ngModelChange)="changeLenguaje()">
            <option [value]="object" *ngFor="let object of languageList">{{object}}</option>
          </select>
        </li> -->
      </ul>
    </div>
    <form class="form-inline col-2 text-center">
      <ul class="navbar-nav my-2 my-lg-0 ">

      </ul>
    </form>
  </div>
</nav>

<mat-menu #menu="matMenu">
  <button mat-menu-item routerLink="/admin/amenities">Comodidades</button>
  <button mat-menu-item routerLink="/admin/category">Categorias</button>
</mat-menu>
