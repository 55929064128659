import { Component, OnInit } from '@angular/core';
import { SessionService } from '../../service/session/session.service';
import { LoadingService } from '../../directive/loading/loading.service';

@Component({
  selector: 'app-tour',
  templateUrl: './tour.component.html',
  styleUrls: ['./tour.component.css']
})
export class TourComponent implements OnInit {
  hotels = [];
  hotelMin = {
    max: 20,
    page: 1,
    perPage:0,
    currentPage:0,
    total:0,
    type:'Español',
    filter: {
      name: "",
      value: ""
    },
  }
  constructor(private session: SessionService,private loading: LoadingService) {
      this.hotelMin.type = this.session.getLanguage();
      if(this.hotelMin.type == null){
        this.hotelMin.type = "Español";
      }
  }

  ngOnInit() {
    this.getHotels();
  }

  getHotels() {
    this.loading.show(true,"Espere un momento...");
    this.session.postRequest("hotels:list", this.hotelMin).subscribe((data: any) => {
      // console.log(data);
      this.hotels = data.object.instanceList;
      this.hotelMin.currentPage = data.object.currentPage;
      this.hotelMin.perPage = data.object.perPage;
      this.hotelMin.total = data.object.total;
      this.loading.hide();
    }, error => {
      console.log("error", error);
      this.loading.hide();
    })
  }

  delete(object){
    this.loading.show(true, "Espere un momento...");
    this.session.postRequest("hotels:delete",object).subscribe((data:any)=>{
      for(let i=0; i < this.hotels.length; i++){
        if(this.hotels[i].id == object.id){
          this.hotels.splice(i,1);
          break;
        }
      }
      this.loading.hide();
    },error=>{
      console.log("error:hotels:delete",error);
      this.loading.hide();
    })
  }

  changePage(ev){
    this.hotelMin.page = ev;
    this.getHotels();
  }

}
