<div class="contentLogin">
  <div class="toolbarLogin">
    <a routerLink="/">
      <img src="assets/img/home/logo.png" alt="keysoft" class="kLogin">
    </a>
    <div class="loginHeader">
      <!-- <div class="btnLoginHeader">
        Inicia session
      </div> -->
    </div>
  </div>
  <div class="loginMain">
    <div class="loginCard">
      <div class="loginCardTitle">
        <img src="assets/img/home/logo.png" alt="keysoft" class="kLogin">

      </div>
      <form class="row justify-content-center">
        <div class="col-12 inputFacturacion">
          <label for="inputEmail4" class="form-label" [ngClass]="{'inValidRedText':complexForm.controls['username'].invalid && complexForm.controls['username'].touched}">Email</label>
          <div class="input-group">
            <div class="input-group-text" [ngClass]="{'inValidRed':complexForm.controls['username'].invalid && complexForm.controls['username'].touched}"><mat-icon>drafts</mat-icon></div>
            <input type="email"
            placeholder="Ingrese correo electrónico"
            name="mName"  class="form-control"
            [ngClass]="{'is-invalid':complexForm.controls['username'].invalid && complexForm.controls['username'].touched}"
            id="inputEmail4"
            [formControl]="complexForm.controls['username']"
            [(ngModel)]="object.username" required>
            <div id="validationServer03Feedback" *ngIf="complexForm.controls['username'].invalid && complexForm.controls['username'].touched" [ngClass]="{'invalid-feedback':complexForm.controls['username'].invalid && complexForm.controls['username'].touched}">Campo requerido.</div>
          </div>
        </div>

        <div class="col-12 inputFacturacion">
          <label for="inputPassword4" class="form-label"  [ngClass]="{'inValidRedText':complexForm.controls['password'].invalid && complexForm.controls['password'].touched}">Contraseña</label>
          <div class="input-group">
            <div class="input-group-text"[ngClass]="{'inValidRed':complexForm.controls['password'].invalid && complexForm.controls['password'].touched}"><mat-icon>lock</mat-icon></div>
            <input type="password" style="letter-spacing: 1.5px;" placeholder="Contraseña" name="mPassword"  class="form-control" id="inputPassword4" [ngClass]="{'is-invalid':complexForm.controls['password'].invalid && complexForm.controls['password'].touched}"  [formControl]="complexForm.controls['password']" [(ngModel)]="object.password" required>
            <div id="validationServer03Feedback" *ngIf="complexForm.controls['password'].invalid && complexForm.controls['password'].touched" [ngClass]="{'invalid-feedback':complexForm.controls['password'].invalid && complexForm.controls['password'].touched}">Campo requerido.</div>

          </div>
        </div>
        <!-- <div class="col-12" style="text-align: right; padding-bottom: 30px;">
          <a routerLink="/">¿ Olvidaste tu contraseña ?</a>
        </div> -->
        <br />
        <div class="button-row">
          <button mat-flat-button color="primary"  (click)="send();" class="col-12 pt-1 pb-1" [disabled]="complexForm.invalid">Iniciar sesión</button>
        </div>
      </form>
      <div class="footerLogin">
        <div class="msnLogin">
        </div>
      </div>
    </div>

  </div>
</div>
