<app-home-menu></app-home-menu>
<div class="row">
  <div class="col-12">
    <ngb-carousel>
      <ng-template ngbSlide *ngFor="let banner of banners">
        <div class="picsum-img-wrapper">
          <img [src]="banner.image" alt="Random first slide" width="100%" style="height:30rem;object-fit:cover;">
        </div>
        <div class="carousel-caption ">
          <p class="text-dark font-robo mt-5 pt-5">Vive <span class="text-success">la mejor aventura </span>en un lugar mágico</p>
          <p class="text-primary mt-3 font-vuja">¡Atrévete a vivirlo!</p>
        </div>
      </ng-template>
    </ngb-carousel>
  </div>
</div>
<div class="row justify-content-center pt-3" style="background-color:#f3f3f3;">
  <div class="col-12 text-center">
    <h1 class="">CONTACTO</h1>
    <h3>Déjanos tus datos y destinos que deseas visitar, nos pondremos en contacto contigo.</h3>
  </div>

  <div class="col-10 cardWhite">
    <div class="row">
      <div class="col-12 col-md-8">
        <div class="row">
          <div class="col-12 col-md-6">
            <label for="exampleFormControlInput1" class="form-label">Nombre</label>
            <input type="email" class="form-control form-control-sm" id="name" name="name" [(ngModel)]="object.name">
          </div>
          <div class="col-12 col-md-6">
            <label for="exampleFormControlInput1" class="form-label">Apellidos</label>
            <input type="email" class="form-control form-control-sm" id="lastname" name="lastname" [(ngModel)]="object.lastname">
          </div>
          <div class="col-12">
            <label for="exampleFormControlInput1" class="form-label">Asunto</label>
            <input type="email" class="form-control form-control-sm" id="assunto" name="assunto" [(ngModel)]="object.asunto">
          </div>
          <div class="col-12 col-md-6">
            <label for="exampleFormControlInput1" class="form-label">Email</label>
            <input type="email" class="form-control form-control-sm" id="email" name="email" [(ngModel)]="object.email">
          </div>
          <div class="col-12 col-md-6">
            <label for="exampleFormControlInput1" class="form-label">Teléfono</label>
            <input type="email" class="form-control form-control-sm" id="telefono" name="telefono" [(ngModel)]="object.phone">
          </div>
          <div class="col-12">
            <label for="exampleFormControlInput1" class="form-label">Mensaje</label>
            <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" name="mensaaje" id="message" [(ngModel)]="object.message"></textarea>

          </div>

          <div class="col-12 row justify-content-center mt-3">
            <div class="col-md-4 col-12">
              <button type="button" class="btn btn-send" (click)="send();">Enviar</button>
            </div>
          </div>
        </div>


      </div>
      <div class="col-12 col-md-4 mt-3">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d238194.00038497787!2d-86.98931939848156!3d21.121285335820236!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8f4c2b05aef653db%3A0xce32b73c625fcd8a!2zQ2FuY8O6biwgUS5SLg!5e0!3m2!1ses!2smx!4v1642719429530!5m2!1ses!2smx" width="300" height="350" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
      </div>
    </div>

  </div>

</div>

<app-home-footer></app-home-footer>
