import { Component, OnInit } from '@angular/core';
import { SessionService } from './../service/session/session.service';
import { LoadingService } from './../directive/loading/loading.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {
  sendersList = [];
  senderMin = {
    max: 20,
    page: 1,
    perPage:0,
    currentPage:0,
    total:0
  }
  constructor(private session: SessionService,private loading: LoadingService) { }

  ngOnInit(): void {
    this.getSender();
  }

  getSender() {
    this.loading.show(true,"Espere un momento...");
    this.session.postRequest("sender:list", this.senderMin).subscribe((data: any) => {
      this.sendersList = data.object.instanceList;
      this.senderMin.currentPage = data.object.currentPage;
      this.senderMin.perPage = data.object.perPage;
      this.senderMin.total = data.object.total;
      this.loading.hide();
    }, error => {
      console.log("error", error);
      this.loading.hide();
    })
  }

  delete(object){
    this.loading.show(true,"Espere un momento...");
    this.session.postRequest("sender:delete", object).subscribe((data: any) => {
      for(let i = 0; i < this.sendersList.length; i++){
        if(this.sendersList[i].id == object.id){
          this.sendersList.splice(i,1);
          break;
        }
      }
      this.loading.hide();
    }, error => {
      console.log("error", error);
      this.loading.hide();
    })
  }

  changePage(ev){
    this.senderMin.page = ev;
    this.getSender();
  }

}
