<app-home-menu></app-home-menu>
<div class="row">
  <div class="col-12">
    <ngb-carousel>
      <ng-template ngbSlide *ngFor="let banner of banners">
        <div class="picsum-img-wrapper">
          <img [src]="banner.image" alt="Random first slide" width="100%" style="height:30rem;object-fit:cover;">
        </div>
        <div class="carousel-caption ">
          <p class="text-dark font-robo mt-5 pt-5">Vive <span class="text-success">la mejor aventura </span>en un lugar mágico</p>
          <p class="text-primary mt-3 font-vuja">¡Atrévete a vivirlo!</p>
        </div>
      </ng-template>
      <!-- <ng-template ngbSlide>
        <div class="picsum-img-wrapper">
          <img src="/assets/img/home/buceo.png" alt="Random first slide" width="100%" style="height:30rem;object-fit:cover;">
        </div>
        <div class="carousel-caption ">
          <p class="text-dark font-robo mt-5 pt-5">Vive <span class="text-success">la mejor aventura </span>en un lugar mágico</p>
          <p class="text-primary mt-3 font-vuja">¡Atrévete a vivirlo!</p>
        </div>
      </ng-template>
      <ng-template ngbSlide>
        <div class="picsum-img-wrapper">
          <img src="/assets/img/home/tiroleza.png" alt="Random first slide" width="100%" style="height:30rem;object-fit:cover;">
        </div>
        <div class="carousel-caption ">
          <p class="text-dark font-robo mt-5 pt-5">Vive <span class="text-success">la mejor aventura </span>en un lugar mágico</p>
          <p class="text-primary mt-3 font-vuja">¡Atrévete a vivirlo!</p>
        </div>
      </ng-template> -->
    </ngb-carousel>
  </div>
</div>
<div class="row section-2 justify-content-center" style="background-color:#f3f3f3;">
  <div class="col-md-10 col-12">
    <div class="row justify-content-center">
      <div class="col-md-4 col-6" *ngFor="let category of categories">
        <div class="card mx-auto">
          <img [src]="category.image" class="card-img-top">
          <div class="card-body">
            <h2 class="card-title text-center" [ngStyle]="{'color':category.color}">{{category.name}}</h2>
            <p class="card-text">{{category.description}}</p>
          </div>
        </div>
      </div>
      <!-- <div class="col-4">
        <div class="card mx-auto">
          <img src="/assets/img/home/img-card-1.png" class="card-img-top">
          <div class="card-body">
            <h2 class="card-title text-info text-center">AVENTURA</h2>
            <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
          </div>
        </div>
      </div>
      <div class="col-4">
        <div class="card mx-auto">
          <img src="/assets/img/home/img-card-2.png" class="card-img-top">
          <div class="card-body">
            <h2 class="card-title text-warning text-center">CULTURA</h2>
            <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</div>
<div class="row justify-content-center section-3">
  <div class="col-md-10 col-12 text-center">
    <img src="/assets/img/home/title-quienes-somos.png" class="mt-2 w-100">
    <h1 class="mt-2 mb-0 text-success">QUIENES SOMOS</h1>
    <hr style="border-color: #48a3b8;height: 0.2rem;width: 20%;margin: 0 auto;">
    <p class="text-start mt-4 text-secondary" *ngIf="object.about_us == ''">En reference tourse, somos una operadora de turismo instalada desde casi 20 años en Cancún Qro. ofreciendoservicios de calidad con atención en sitio, además asesorando al cliente  para tomar la mejor decisión  en la compra de sus tours. Somos especializados en crear itinerarios personalizados, al gusto de cada cliente. De igual manera, trabajamos en estrecha colaboración con varias comunidades: así como profesionales locales para ofrecerles experiencias únicas e inigualables.</p>
    <p class="text-start mt-4 text-secondary" *ngIf="object.about_us != ''">{{object.about_us}}</p>

    <a class="btn-orange">Conozca mas ...</a>
  </div>
</div>
<div class="section-4  pb-4">
  <div class="row justify-content-center">
    <div class="col-12 text-center">
      <h1 class="text-white pt-4 pb-3">TOURS FAVORITOS</h1>
    </div>
  </div>

  <div class="">
    <div class="mob-scroll">
      <div class="col-md-3 col-10" *ngFor="let tour of toursFav">
        <div class="card mx-auto">
          <img [src]="tour.image" class="card-img-top" style="height: 18rem;">
          <div class="card-body">
            <!-- <p class="card-title text-info text-center mb-0">CAPITAL MAYA</p> -->
            <h2 class="text-black fw-bold">{{tour.shortName}}</h2>
            <p class="card-text">{{tour.review}}</p>
            <div class="row justify-content-end mt-3">
              <div class="col-8" style="display: contents;">
                <button type="button" class="btn btn-more-tour" routerLink="/home/toursdetails/{{tour.id}}">Conozca <mat-icon class="material-icons-outlined">add_circle</mat-icon></button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>

</div>
<div class="row section-5 bg-dark justify-content-center">
  <div class="col-md-10 col-12 text-center">
    <p class="display-5 pt-5 pb-5 text-light">
      <em *ngIf="object.section1 == ''">"La satisfacción del cliente es <span class="fw-bold">nuestra misión </span>"</em>
      <em *ngIf="object.section1 != ''">{{object.section1}}</em>
    </p>
  </div>
</div>
<div class="row section-6 justify-content-center section-6 ">
  <div class="col-md-10 col-12">
    <div class="row justify-content-center mt-2 mb-2">
      <div class="col-md-6 col-12">
        <div class="row">
          <div class="col-4 p-0 " style="height: 12.5rem;" >
            <img src="/assets/img/home/img-tour-1.png" style="object-fit:none;height:100%;width:100%;">
          </div>
          <div class="col-4 p-0" style="height: 12.5rem;">
            <img src="/assets/img/home/img-tour-2.png" style="object-fit:none;height:100%;width:100%;">
          </div>
          <div class="col-4 p-0" style="height: 12.5rem;">
            <img src="/assets/img/home/img-tour-3.png" style="object-fit:none;height:100%;width:100%;">
          </div>
          <div class="col-4 p-0" style="height: 12.5rem;">
            <img src="/assets/img/home/img-tour-4.png" style="object-fit:none;height:100%;width:100%;">
          </div>
          <div class="col-4 p-0" style="height: 12.5rem;">
            <img src="/assets/img/home/img-tour-5.png" style="object-fit:none;height:100%;width:100%;">
          </div>
          <div class="col-4 p-0" style="height: 12.5rem;">
            <img src="/assets/img/home/img-tour-6.png"  style="object-fit:none;height:100%;width:100%;">
          </div>
          <div class="col-4 p-0" style="height: 12.5rem;">
            <img src="/assets/img/home/img-tour-7.png" style="object-fit:none;height:100%;width:100%;">
          </div>
          <div class="col-4 p-0" style="height: 12.5rem;">
            <img src="/assets/img/home/img-tour-8.png" style="object-fit:none;height:100%;width:100%;">
          </div>
          <div class="col-4 p-0" style="height: 12.5rem;">
            <img src="/assets/img/home/img-tour-1.png" style="object-fit:none;height:100%;width:100%;">
          </div>
        </div>
      </div>
      <div class="col-md-6 col-12 our-section">
        <p class="display-4 pt-3">Nuestros tours</p>
        <p class="mt-5 fw-light text-secondary" *ngIf="object.our_tours == ''">Les ofrecemos aquí un recorrido excepcional que reune todos los imperdibles de la región: Xel-há, Tulum, Mérida, Chichen itzá y todos los sitios mágicos del caribe mexicano. Conoceran las tradiciones milenarias de la civilización Maya y podrán disfrutar de un merecido descanso en el mar caribe.</p>
        <p class="mt-5 fw-light text-secondary" *ngIf="object.our_tours != ''">{{object.our_tours}}</p>

        <img src="/assets/img/home/img-tour-5.png" width="100%">
      </div>
    </div>
  </div>
</div>
<div class="videoSection" *ngIf="object.video != ''">
  <div class="videoFrame">
    <!-- <span class="videoTile">{{object.video_title}}</span> -->
    <iframe class="videoIFrame" [src]="object.video" title="{{object.video_title}}" frameborder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
  </div>
</div>
<app-home-footer></app-home-footer>
