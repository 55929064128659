<nav class="navbar navbar-expand-lg navbar-light">
  <div class="container-fluid">
    <a class="navbar-brand" href="#">
      <img src="/assets/img/home/logo.png" alt="" width="150" class="d-inline-block align-text-top">
    </a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNav">
      <ul class="navbar-nav justify-content-evenly" style="width:100%;">
        <li class="nav-item">
          <a class="nav-link" aria-current="page" href="#">INICIO</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/home/tours">TOURS</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/home/contact">TOURS PRIVADOS</a>
        </li>
        <!-- <li class="nav-item">
          <a class="nav-link">SUSTENTABILIDAD</a>
        </li> -->
        <li class="nav-item btn-round">
          <a class="nav-link text-white">CONTACTO</a>
        </li>
      </ul>
    </div>
  </div>
</nav>
