import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { SessionService } from '../../../service/session/session.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { LoadingService } from '../../../directive/loading/loading.service';
import { NotificationsService, Options, NotificationAnimationType } from 'angular2-notifications';

@Component({
  selector: 'app-tour-update',
  templateUrl: './tour-update.component.html',
  styleUrls: ['./tour-update.component.css']
})
export class TourUpdateComponent implements OnInit {

  @ViewChild('tabName')tabCtrl:NgbNavChangeEvent;
  complexForm: FormGroup;
  contentHotels:{
    image: {
      file: '',
      name: ''
    },
  }
  hotelModel={
    id:'',
    shortName:'',
    review:'',
    name:'',
    start_number:0,
    description:'',
    address:'',
    phone:'',
    email:'',
    web_site:'',
    category:'Hotel',
    image:'',
    name_image:'',
    status:true,
    type:'Español',
    fav:false,
    tags:''
  }
  objectAmenitiesModel={
    id:'',
    objectType:'Hotel',
    objectId:'',
    amenitiesId:'',
    status:true
  }
  hotelGalleryModel={
    id:'',
    file:'',
    nameFile:'',
    hotel:{
      id:''
    }
  }

  noticludeModel={
    id:'',
    objectType:'Hotel',
    objectId:'',
    amenitiesId:'',
    status:true
  }
  hotelAttachmentModel={
    id:'',
    file:'',
    nameFile:'',
    hotel:{
      id:''
    }
  }

  object = JSON.parse(JSON.stringify(this.hotelModel));
  amenitiesList=[];
  hotelAmenitiesList = [];
  galleryList = [];
  notincludeList = [];
  notincludeAttachementList = [];
  attachementList = [];

  fileModel = {
    img:{
      name:'',
      type:'',
      file:''
    },
    uploadted:false
  }

  metadata={
    file:JSON.parse(JSON.stringify(this.fileModel)),
    fileAttachment:JSON.parse(JSON.stringify(this.fileModel)),
    whitOutClass:false,
    mainFile:JSON.parse(JSON.stringify(this.fileModel))
  }

  metadataImage={
    file:{
      name:'',
      file:'',
    }
  }
  languageList = [
    'Español',
    'Inglés'
  ];
  active = '1';

  notifyOptions:Options  = {
    timeOut:2000,
    showProgressBar:true,
    pauseOnHover: true,
    clickToClose:true,
    position:["top","center"],
    preventDuplicates:true,
    animate: NotificationAnimationType.Fade
  }
  categoryMin = {
    max: 200,
    page: 1,
  }
  listCategory = [];
  constructor(
    public fb: FormBuilder,
    protected sessionService: SessionService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private loadding: LoadingService,
    private _notification: NotificationsService,
  ) {
    this.complexForm = fb.group({
      'name': [null, Validators.compose([Validators.required])],
      'shortName': [null, Validators.compose([Validators.required])],
      'review': [null, Validators.compose([Validators.required])],
      'description': [null, Validators.compose([Validators.required])],
      'start_number': [null, Validators.compose([Validators.required])],
      'address': [null, Validators.compose([Validators.required])],
      'phone': [null, Validators.compose([Validators.required])],
      'email': [null, Validators.compose([Validators.required])],
    });
   }

  ngOnInit() {
    this.getAmenities();
    this.getcategorys();
    this.activatedRoute.params.subscribe((params: Params) => {
      if(params['uuid'] == 'new'){
        // no hacemos nada por el momento
      }else{
        this.object.id = params['uuid'];
        // cargamos los datos de la BD
        this.loadding.show(true,"Espere un momento...");
        this.sessionService.postRequest('hotels:get',this.object).subscribe((data:any)=>{
          this.object = JSON.parse(JSON.stringify(data.object));
          this.metadata.mainFile.uploadted = true;
          this.metadata.mainFile.img.file = this.object.image;
          this.metadata.mainFile.img.name  = this.object.name_image;
          this.getHotelAmenities();
          this.getNotInclude();
          this.findGallery();
          this.findAttachments();
          this.loadding.hide();
        },
        (error)=>{
          console.log('Error:hotels:get',error)
          this.loadding.hide();
        })
      }
    });
  }

  getAmenities(){
    this.loadding.show(true,"Espere un momento...");
    this.sessionService.postRequest("amenities:list",{}).subscribe((data:any)=>{
      // console.log(data);
      this.amenitiesList = data.object.instanceList;
      this.notincludeAttachementList = JSON.parse(JSON.stringify(data.object.instanceList));
      this.loadding.hide();
    },error=>{
      console.log("error", error);
      this.loadding.hide();
    })
  }

  amenitieSelected(object:any){
    // console.log(object);
    if(object.selected){
      //eliminamos la relacion con el hotels
      for(let i=0; i<this.hotelAmenitiesList.length; i++){
        if(this.hotelAmenitiesList[i].amenitiesId == object.id){
          this.loadding.show(true,"Espere un momento...");
          this.sessionService.postRequest("objectAmenities:delete",this.hotelAmenitiesList[i]).subscribe((data:any)=>{
            object.selected = false;
            this.hotelAmenitiesList.splice(i,1);
            this.loadding.hide();
          },error=>{
            console.log("error:objectAmenities:delete",error);
            this.loadding.hide();
          })
        }
      }
    }else{
      this.loadding.show(true,"Espere un momento...");
      let item = JSON.parse(JSON.stringify(this.objectAmenitiesModel));
      item.amenitiesId = object.id;
      item.objectId = this.object.id;
      this.sessionService.postRequest("objectAmenities:update",item).subscribe((data:any)=>{
        item.id = data.object.id;
        this.hotelAmenitiesList.push(item);
        // console.log(data);
        //creamos la relacion con el hotels
        object.selected = true;
        this.loadding.hide();
      },error=>{
        console.log("error:objectAmenities:update",error);
        this.loadding.hide();
      })
    }
  }

  getHotelAmenities(){
    this.loadding.show(true,"Espere un momento...");
    this.sessionService.postRequest("objectAmenities:findAllByObjectTypeAndObjectId",{objectType:'Hotel',objectId:this.object.id}).subscribe((data:any)=>{
      // console.log(data);
      this.hotelAmenitiesList = data.object.instanceList;
      for(let i = 0; i < this.hotelAmenitiesList.length; i++){
        for(let j = 0; j < this.amenitiesList.length; j++){
          if(this.hotelAmenitiesList[i].amenitiesId == this.amenitiesList[j].id){
            this.amenitiesList[j].selected = true;
          }
        }
      }
      this.loadding.hide();
    },error=>{
      console.log("Error:objectAmenities:findAllByObjectTypeAndObjectId",error);
      this.loadding.hide();
    })
  }

  /**funcionalidad para obtener no incluye
  */
  getNotInclude(){
    this.loadding.show(true,"Espere un momento...");
    this.sessionService.postRequest("notinclude:findAllByObjectTypeAndObjectId",{objectType:'Hotel',objectId:this.object.id}).subscribe((data:any)=>{
      // console.log(data);
      this.notincludeList = data.object.instanceList;
      for(let i = 0; i < this.notincludeList.length; i++){
        for(let j = 0; j < this.notincludeAttachementList.length; j++){
          if(this.notincludeList[i].amenitiesId == this.notincludeAttachementList[j].id){
            this.notincludeAttachementList[j].selected = true;
          }
        }
      }
      this.loadding.hide();
    },error=>{
      console.log("Error:notinclude:findAllByObjectTypeAndObjectId",error);
      this.loadding.hide();
    })
  }

  notIncludeSelected(object:any){
    // console.log(object);
    if(object.selected){
      //eliminamos la relacion con el hotels
      for(let i=0; i<this.notincludeList.length; i++){
        if(this.notincludeList[i].amenitiesId == object.id){
          this.loadding.show(true,"Espere un momento...");
          this.sessionService.postRequest("notinclude:delete",this.notincludeList[i]).subscribe((data:any)=>{
            object.selected = false;
            this.notincludeList.splice(i,1);
            this.loadding.hide();
          },error=>{
            console.log("error:notinclude:delete",error);
            this.loadding.hide();
          })
        }
      }
    }else{
      this.loadding.show(true,"Espere un momento...");
      let item = JSON.parse(JSON.stringify(this.objectAmenitiesModel));
      item.amenitiesId = object.id;
      item.objectId = this.object.id;
      this.sessionService.postRequest("notinclude:update",item).subscribe((data:any)=>{
        item.id = data.object.id;
        this.notincludeList.push(item);
        // console.log(data);
        //creamos la relacion con el hotels
        object.selected = true;
        this.loadding.hide();
      },error=>{
        console.log("error:notinclude:update",error);
        this.loadding.hide();
      })
    }
  }

  /*funcion para subir una imagen a la galeria*/
  uploadAFile(){
    this.loadding.show(true,"Espere un momento...");
    let hotelGallery = JSON.parse(JSON.stringify(this.hotelGalleryModel));
    hotelGallery.hotel.id = this.object.id;
    hotelGallery.file = 'http://127.0.0.1:8000/hotel/'+this.metadata.file.img.name;
    hotelGallery.nameFile = this.metadata.file.img.name;
    this.sessionService.postRequest("hotelGallery:update",hotelGallery).subscribe((data:any)=>{
      hotelGallery.id = data.object.id;
      this.galleryList.push(hotelGallery);
      this.metadata.file = JSON.parse(JSON.stringify(this.fileModel));
      this.loadding.hide();
    },error=>{
      console.log("error,hotelGallery:update",error);
      this.loadding.hide();
    })
    console.log(hotelGallery);
  }
  uploadAFileAttachemnt(){
    this.loadding.show(true,"Espere un momento...");
    let hotelGallery = JSON.parse(JSON.stringify(this.hotelGalleryModel));
    hotelGallery.hotel.id = this.object.id;
    hotelGallery.file = 'http://127.0.0.1:8000/attachment/'+this.metadata.fileAttachment.img.name;
    hotelGallery.nameFile = this.metadata.fileAttachment.img.name;
    this.sessionService.postRequest("hotelAttachment:update",hotelGallery).subscribe((data:any)=>{
      hotelGallery.id = data.object.id;
      this.attachementList.push(hotelGallery);
      this.metadata.fileAttachment = JSON.parse(JSON.stringify(this.fileModel));
      this.loadding.hide();
    },error=>{
      console.log("error,hotelGallery:update",error);
      this.loadding.hide();
    })
  }

  findGallery(){
    this.loadding.show(true,"Espere un momento...");
    this.sessionService.postRequest("hotelGallery:findAllByHotel",{hotel:{id:this.object.id}}).subscribe((data:any)=>{
      this.galleryList = data.object.instanceList;
      this.loadding.hide();
    },error=>{
      console.log("error:hotelGallery:findAllByHotel",error);
      this.loadding.hide();
    })
  }
  findAttachments(){
    this.loadding.show(true,"Espere un momento...");
    this.sessionService.postRequest("hotelAttachment:findAllByHotel",{hotel:{id:this.object.id}}).subscribe((data:any)=>{
      this.attachementList = data.object.instanceList;
      this.loadding.hide();
    },error=>{
      console.log("error:hotelAttachment:findAllByHotel",error);
      this.loadding.hide();
    })
  }

  deleteAFile(object:any){
    this.loadding.show(true,"Espere un momento...");
    this.sessionService.postRequest("hotelGallery:delete",object).subscribe(()=>{
      for(let i=0; i < this.galleryList.length; i++){
        if(object.id == this.galleryList[i].id){
          this.galleryList.splice(i,1);
          break;
        }
      }
      this.loadding.hide();
    },error=>{
      console.log("error:hotelGallery:delete",error);
      this.loadding.hide();
    })
  }

  downloadAFile(object:any) {
    this.loadding.show(true,"Espere un momento...");
    this.sessionService.postRequest("hotelGallery:get",object).subscribe((data:any)=>{
      let elem = document.createElement('a');
      elem.href = data.object.file;
      elem.setAttribute('download', object.nameFile);
      document.body.appendChild(elem);
      elem.click();
      this.loadding.hide();
    },error=>{
      console.log("error:hotelGallery:get",error);
      this.loadding.hide();
    })
  }

  deleteAFileAttachment(object:any){
    this.loadding.show(true,"Espere un momento...");
    this.sessionService.postRequest("hotelAttachment:delete",object).subscribe(()=>{
      for(let i=0; i < this.attachementList.length; i++){
        if(object.id == this.attachementList[i].id){
          this.attachementList.splice(i,1);
          break;
        }
      }
      this.loadding.hide();
    },error=>{
      console.log("error:hotelAttachment:delete",error);
      this.loadding.hide();
    })
  }

  downloadAFileAttachment(object:any) {
    this.loadding.show(true,"Espere un momento...");
    this.sessionService.postRequest("hotelAttachment:get",object).subscribe((data:any)=>{
      let elem = document.createElement('a');
      elem.href = data.object.file;
      elem.setAttribute('download', object.nameFile);
      document.body.appendChild(elem);
      elem.click();
      this.loadding.hide();
    },error=>{
      console.log("error:hotelAttachment:get",error);
      this.loadding.hide();
    })
  }



  send(exit:any){
    this.loadding.show(true,"Espere un momento...");
    if(this.metadata.mainFile.uploadted){
      this.object.image = 'http://127.0.0.1:8000/hotel/'+this.metadata.mainFile.img.name;
      this.object.name_image = this.metadata.mainFile.img.name;
      this.sessionService.postRequest("hotels:update",this.object).subscribe((data:any)=>{
        this.object.id = data.object.id;
        if(exit){
          this.router.navigate(['/admin/tour']);
        }
        this.loadding.hide();
      },error=>{
        console.log("Error:hotels:update",error);
        this.loadding.hide();
      })
    }else{
      this._notification.warn("Atención","Suba la imagen de portada del tour para continuar",this.notifyOptions);
      this.loadding.hide();

    }

  }

  /**función para obtener el listado de categorias
  */
  getcategorys(){
    this.sessionService.postRequest("category:list",this.categoryMin).subscribe((data:any)=>{
      this.listCategory = data.object.instanceList;
    },error=>{
      console.log("error:category:list",error);
    })
  }

  tabSelect(object:any){
    console.log(object);
    if(object == '3' || object == '4'){
      if(this.object.name != '' && this.object.description != '' && this.object.address != '' && this.object.phone != '' && this.object.email != ''){
        this.send(false);
      }
    }
    // this.tabCtrl.activeId = object;
  }

  changewhitOutClass(){
    this.metadata.whitOutClass = !this.metadata.whitOutClass;
    if(this.metadata.whitOutClass){
      this.object.start_number = 0;
    }
  }


  checkChange(){
    if(this.metadata.mainFile.uploadted){
      this.active = '2';
    }
  }

}
