<app-menu-admin></app-menu-admin>
<div class="row">
  <div class="col-12 col-md-10">
    <div class="row justify-content-end">
      <div class="col-3 text-center mt-2">
        <a class="tittle-servation mt-4" placement="top" ngbTooltip="Regresar" routerLink="/admin/tour"><i class="fas fa-angle-double-left"></i>&ensp;Regresar</a>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-12 col-md-10">
        <div class="d-flex">
          <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-pills" orientation="vertical" (shown)="tabSelect($event)">
            <li ngbNavItem="1">
              <a ngbNavLink>PRIMERA VISTA</a>
              <ng-template ngbNavContent>
                <div style="padding-left: 30px;">
                  <div class="col-12">
                    <p class="text-justify">Indica los datos a mostrar en la primera vista, considerando breve reseña no mayor a 80 caracteres para mostrar como resumen del tour.</p>
                  </div>
                  <div class="row ml-4 pl-4">
                    <div class="col-3">
                      <div class="form-group">
                        <div class="form-check">
                          <input class="form-check-input" type="checkbox" id="gridCheck" [(ngModel)]="object.status">
                          <label class="form-check-label" for="gridCheck">
                            Activar Tour
                          </label>
                        </div>
                      </div>
                    </div>
                    <div class="col-3">
                      <div class="form-group">
                        <div class="form-check">
                          <input class="form-check-input" type="checkbox" id="gridCheckFav" [(ngModel)]="object.fav">
                          <label class="form-check-label" for="gridCheckFav">
                            Tour Favorito
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group row mb-1">
                    <label for="staticEmail" class="col-sm-1 col-1 col-form-label">*</label>
                    <div class="col-sm-11 col-11">
                      <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Nombre Corto" [(ngModel)]="object.shortName">
                    </div>
                  </div>
                  <div class="form-group row mb-1">
                    <label for="staticEmail" class="col-sm-1 col-1 col-form-label">*</label>
                    <div class="col-sm-11 col-11">
                      <textarea name="messaje" rows="6" cols="80" class="form-control" placeholder="Reseña" [(ngModel)]="object.review"></textarea>
                    </div>
                  </div>
                  <div class="form-group row mb-1">
                    <label for="staticEmail" class="col-sm-1 col-1 col-form-label">*</label>
                    <div class="col-sm-10 col-10">
                      <!-- <sti-file-directive [stiFile]="metadataImage.file"></sti-file-directive> -->
                      <app-upload-img-min [uploadCode]="'hotel:upload'" [mFile]="metadata.mainFile"></app-upload-img-min>
                    </div>
                  </div>

                  <br>
                  <div class="form-group row mb-1">
                    <label for="staticEmail" class="col-sm-1 col-1 col-form-label">*</label>
                    <div class="col-sm-11 col-11">
                      <select class="form-control" [(ngModel)]="object.category" placeholder="Categoria">
                        <option value="">-- CATEGORIA --</option>
                        <option value="{{object.name}}" *ngFor="let object of listCategory">{{object.name}}</option>
                      </select>
                    </div>
                  </div>
                  <div class="form-group row mb-1">
                    <label for="staticEmail" class="col-sm-1 col-1 col-form-label">*</label>
                    <div class="col-sm-11 col-11">
                      <select class="form-control" [(ngModel)]="object.type">
                        <option [value]="object" *ngFor="let object of languageList">{{object}}</option>
                      </select>
                    </div>
                  </div>
                  <div class="form-group row mb-1">
                    <label for="staticEmail" class="col-sm-1 col-1 col-form-label">*</label>
                    <div class="col-sm-11 col-11">
                      <input type="text" class="form-control" id="tag" aria-describedby="tag" placeholder="Tags" [(ngModel)]="object.tags">
                    </div>
                  </div>
                </div>
                <div class="row justify-content-end mt-3 mb-3">
                  <div class="col-12 col-md-6">
                    <button mat-flat-button color="primary" style="width:100%;"  (click)="send(false); checkChange();" [disabled]="object.shortName == '' || object.review == ''">SIGUIENTE</button>
                  </div>
                </div>
              </ng-template>
            </li>
            <li ngbNavItem="2">
              <a ngbNavLink>GENERALES</a>
              <ng-template ngbNavContent>
                <div style="padding-left: 30px;">
                  <div class="col-12">
                    <p class="text-justify">Indique los datos generales para el alta del Tour.</p>
                  </div>
                  <div class="form-group row mb-1">
                    <label for="staticEmail" class="col-sm-1 col-1 col-form-label">*</label>
                    <div class="col-sm-11 col-11">
                      <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Nombre Completo Tour" [(ngModel)]="object.name">
                    </div>
                  </div>
                  <div class="form-group row">
                    <label for="staticEmail" class="col-sm-1 col-1 col-form-label">*</label>
                    <div class="col-sm-11 col-11">
                      <textarea name="messaje" rows="4" cols="80" class="form-control" placeholder="Descripción del Tour" [(ngModel)]="object.description"></textarea>
                    </div>
                  </div>
                  <div class="form-group row mb-1 ">
                    <label for="staticEmail" class="col-sm-1 col-1 col-form-label">*</label>
                    <div class="col-sm-6 col-6">
                      <input type="number" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Número de estrellas" [(ngModel)]="object.start_number" *ngIf="!metadata.whitOutClass">
                    </div>
                    <div class="col-sm-5 col-5">
                      <button type="button" class="btn btn-link" (click)="changewhitOutClass();">
                        <i class="far fa-check-square" *ngIf="metadata.whitOutClass"></i>
                        <i class="far fa-square" *ngIf="!metadata.whitOutClass"></i>
                        Sin Estrellas
                      </button>
                    </div>
                  </div>
                  <div class="form-group row mb-1">
                    <label for="staticEmail" class="col-sm-1 col-1 col-form-label">*</label>
                    <div class="col-sm-11 col-11">
                      <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Web" [(ngModel)]="object.web_site">
                    </div>
                  </div>
                  <div class="col-12">
                    <p class="text-justify">Indique la Dirección del Tour, separando por comas para la presentación correcta de los datos.</p>
                  </div>
                  <div class="form-group row ">
                    <label for="staticEmail" class="col-sm-1 col-1 col-form-label">*</label>
                    <div class="col-sm-11 col-11">
                      <textarea name="messaje" rows="4" cols="80" class="form-control" placeholder="Dirección" [(ngModel)]="object.address"></textarea>
                    </div>
                  </div>
                  <div class="form-group row mb-1">
                    <label for="staticEmail" class="col-sm-1 col-1 col-form-label">*</label>
                    <div class="col-sm-11 col-11">
                      <input class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Teléfono" [(ngModel)]="object.phone">
                    </div>
                  </div>
                  <div class="form-group row mb-1">
                    <label for="staticEmail" class="col-sm-1 col-1 col-form-label">*</label>
                    <div class="col-sm-11 col-11">
                      <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Correo" [(ngModel)]="object.email">
                    </div>
                  </div>
                </div>
                <div class="row justify-content-end mt-3 mb-3">
                  <div class="col-12 col-md-6">
                    <button mat-flat-button color="primary" style="width:100%;"  (click)="send(false); active = '3';" [disabled]="object.shortName == '' || object.review == '' || object.name == '' || object.description == ''">SIGUIENTE</button>
                  </div>
                </div>
              </ng-template>
            </li>
            <li ngbNavItem="3" [disabled]="object.id == ''">
              <a ngbNavLink >FOTOS & ADJUNTOS</a>
              <ng-template ngbNavContent>
                <div class="row" style="padding-left: 30px;">
                  <div class="col-12">
                    <p class="text-justify">Sube las imagenes a mostrar.</p>
                  </div>
                  <div class="form-group row mb-1">
                    <label for="staticEmail" class="col-sm-1 col-1 col-form-label">*</label>
                    <div class="col-sm-11 col-11">
                      <div class="col-12">
                        <app-upload-img-min [uploadCode]="'hotelGallery:upload'" [mFile]="metadata.file" (imageUploated)="uploadAFile();"></app-upload-img-min>
                      </div>
                      <div class="col-12">
                        <hr>
                        <table class="table">
                          <thead>
                            <tr>
                              <th>Nombre</th>
                              <th>Acciones</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let object of galleryList">
                              <th>{{object.nameFile}}</th>
                              <th>
                                <button mat-icon-button (click)="deleteAFile(object);" placement="top" ngbTooltip="Eliminar registro">
                                  <mat-icon>delete</mat-icon>
                                </button>
                                <button mat-icon-button (click)="downloadAFile(object);" placement="top" ngbTooltip="Descargar registro">
                                  <mat-icon>file_download</mat-icon>
                                </button>
                              </th>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row" style="padding-left: 30px;">
                  <div class="col-12">
                    <p class="text-justify">Sube los documentos a descargar en el tour.</p>
                  </div>
                  <div class="form-group row mb-1">
                    <label for="staticEmail" class="col-sm-1 col-1 col-form-label">*</label>
                    <div class="col-sm-11 col-11">
                      <div class="col-12">
                        <app-upload-attachment-min [uploadCode]="'hotelAttachment:upload'" [mFile]="metadata.fileAttachment" (imageUploated)="uploadAFileAttachemnt();"></app-upload-attachment-min>
                      </div>
                      <div class="col-12">
                        <hr>
                        <table class="table">
                          <thead>
                            <tr>
                              <th>Nombre</th>
                              <th>Acciones</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let object of attachementList">
                              <th>{{object.nameFile}}</th>
                              <th>
                                <button mat-icon-button (click)="deleteAFileAttachment(object);" placement="top" ngbTooltip="Eliminar registro">
                                  <mat-icon>delete</mat-icon>
                                </button>
                                <button mat-icon-button (click)="downloadAFileAttachment(object);" placement="top" ngbTooltip="Descargar registro">
                                  <mat-icon>file_download</mat-icon>
                                </button>
                              </th>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row justify-content-end mb-3 mt-3">
                  <div class="col-12 col-md-6">
                    <button mat-flat-button color="primary" style="width:100%;"  (click)="send(false); active='4'" >SIGUIENTE</button>
                  </div>
                </div>
              </ng-template>
            </li>
            <li ngbNavItem="4"  [disabled]="object.id == ''">
              <a ngbNavLink >INCLUYE & NO INCLUYE</a>
              <ng-template ngbNavContent>

                <div style="padding-left: 30px;">
                  <div class="col-12">
                    <p class="text-justify">Indica las Amenidades con las que cuenta el Tour a registrar.</p>
                  </div>
                  <div class="row">
                    <div class="col-2 text-check ml-2" *ngFor="let object of amenitiesList; let i = index;">
                      <button mat-icon-button color="accent" *ngIf="object.selected" (click)="amenitieSelected(object);">
                        <mat-icon>check_circle</mat-icon>
                      </button>

                      <button mat-icon-button color="accent" *ngIf="!object.selected" (click)="amenitieSelected(object);">
                        <mat-icon>radio_button_unchecked</mat-icon>
                      </button>

                      <img src="{{object.icon}}" alt="{{object.icon}}" width="30px">
                      <p>{{object.name}}</p>
                    </div>
                  </div>
                </div>

                <div style="padding-left: 30px;">
                  <div class="col-12">
                    <p class="text-justify">Indica las Amenidades que <strong>No incluye</strong> el Tour a registrar.</p>
                  </div>
                  <div class="row">
                    <div class="col-2 text-check ml-2" *ngFor="let object of notincludeAttachementList; let i = index;">
                      <button mat-icon-button color="accent" *ngIf="object.selected" (click)="notIncludeSelected(object);">
                        <mat-icon>check_circle</mat-icon>
                      </button>

                      <button mat-icon-button color="accent" *ngIf="!object.selected" (click)="notIncludeSelected(object);">
                        <mat-icon>radio_button_unchecked</mat-icon>
                      </button>

                      <img src="{{object.icon}}" alt="{{object.icon}}" width="30px">
                      <p>{{object.name}}</p>
                    </div>
                  </div>
                </div>
                <div class="row justify-content-end mb-3 mt-3">
                  <div class="col-12 col-md-10">
                    <button mat-flat-button color="primary" style="width:100%;"  (click)="send(true);" [disabled]="object.shortName == '' || object.review == '' || object.name == '' || object.description == ''">Guardar</button>
                  </div>
                </div>
              </ng-template>
            </li>
          </ul>

          <div [ngbNavOutlet]="nav"></div>
        </div>

      </div>

    </div>

  </div>
</div>
