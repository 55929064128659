<app-menu-admin></app-menu-admin>
<div class="row justify-content-center">
  <div class="col-12 col-md-10">
    <div class="col-12">
      <h2>Mensajes</h2>
    </div>
    <div class="card-body" style="color: #666666;">
      <div class="row">
        <div class="col-12 table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th>Nombre</th>
                <th>Asunto</th>
                <th>Email</th>
                <th>Teléfono</th>
                <th>Mensaje</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let object of sendersList">
                <th>{{object.name + ' ' + object.lastname}}</th>
                <td>{{object.asunto}}</td>
                <td>{{object.email}}</td>
                <td>{{object.phone}}</td>
                <td>{{object.message}}</td>
                <td>
                  <button mat-icon-button  placement="top" ngbTooltip="Eliminar registro" (click)="delete(object);">
                    <mat-icon>delete</mat-icon>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="row justify-content-center">
            <div class="col-11 align-self-center">
              <!-- <ngb-pagination [collectionSize]="senderMin.total" [(page)]="senderMin.currentPage" [pageSize]="senderMin.perPage" [maxSize]="5" [rotate]="true" [ellipses]="false" [boundaryLinks]="true" (pageChange)="changePage($event);"></ngb-pagination> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
