<app-menu-admin></app-menu-admin>
<div class="row justify-content-center" style="margin-top: 50px;">
  <div class="col-12 col-md-10 cardTono">
    <div class="row justify-content-between">
      <div class="col-8">
        <h2 class="mb-2">Banners</h2>
      </div>
      <div class="col-3 text-right">
        <button mat-button color="primary" (click)="updateBanner('new');"><mat-icon aria-hidden="false" aria-label="Nuevo">add_circle</mat-icon> Nuevo Banner</button>
      </div>
    </div>
    <table class="table table-general">
      <thead class="header-table">
        <tr>
          <th>Imagen</th>
          <th>Acciones</th>
        </tr>
      </thead>

      <tbody>
        <tr *ngFor="let object of list; let i = index;">
          <td>
            <img src="{{object.image}}" alt="image" width="100px">
          </td>

          <td>
            <div class="table-actions">
              <button mat-icon-button class="naturaColor"  style="font-size: smaller;" matTooltip="Editar" (click)="updateBanner(object.id);" ><mat-icon>edit</mat-icon></button>
              <button mat-icon-button class="naturaColor"  style="font-size: smaller;" matTooltip="Borrar" (click)="deleteBanner(object);" ><mat-icon>delete</mat-icon></button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="row justify-content-end" style="margin-left:0px; margin-right:0px;">
      <div class="col-6 ">
        <mat-paginator [length]=paginator.total [pageSize]="10" [pageSizeOptions]=paginator.pageSizeOptions (page)="pageEvent = $event; onPaginateChange($event)" style="background: #f4f4f5;">
        </mat-paginator>
      </div>
    </div>
  </div>
</div>
