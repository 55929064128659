import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';


import { SessionModule } from './service/session/session.module';
import { LoadingModule } from './directive/loading/loading.module';

import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { DateAdapter as DateAdapterMaterial, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { MatPaginatorModule } from '@angular/material/paginator';
import { SimpleNotificationsModule } from 'angular2-notifications';
import { NgxGalleryModule } from '@kolkov/ngx-gallery';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HomeComponent } from './home/home.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AdminComponent } from './admin/admin.component';
import { LoginComponent } from './login/login.component';
import { MenuAdminComponent } from './admin/menu-admin/menu-admin.component';
import { AdminSlidersComponent } from './admin/admin-sliders/admin-sliders.component';
import { AdminSlidersUpdateComponent } from './admin/admin-sliders/admin-sliders-update/admin-sliders-update.component';
import { TourComponent } from './admin/tour/tour.component';
import { TourUpdateComponent } from './admin/tour/tour-update/tour-update.component';
import { UploadImgMinComponent } from './directive/upload-img-min/upload-img-min.component';
import { UploadAttachmentMinComponent } from './directive/upload-attachment-min/upload-attachment-min.component';

import { AdminAmenitiesComponent } from './admin/admin-amenities/admin-amenities.component';
import { AdminAmenitiesUpdateComponent } from './admin/admin-amenities/admin-amenities-update/admin-amenities-update.component';
import { AdminCategoryComponent } from './admin/admin-category/admin-category.component';
import { AdminCategoryUpdateComponent } from './admin/admin-category/admin-category-update/admin-category-update.component';
import { HomeFooterComponent } from './home/home-footer/home-footer.component';
import { HomeMenuComponent } from './home/home-menu/home-menu.component';
import { HomeToursComponent } from './home/home-tours/home-tours.component';
import { HomeContactComponent } from './home/home-contact/home-contact.component';
import { HomeToursDetailsComponent } from './home/home-tours/home-tours-details/home-tours-details.component';
import { AdminCompanyComponent } from './admin/admin-company/admin-company.component';


export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'DD/MM/YYYY',
    dateA11yLabel: 'DD/MM/YYYY',
    monthYearA11yLabel: 'DD/MM/YYYY',
  },
};

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AdminComponent,
    LoginComponent,
    MenuAdminComponent,
    AdminSlidersComponent,
    AdminSlidersUpdateComponent,
    TourComponent,
    TourUpdateComponent,
    UploadImgMinComponent,
    AdminAmenitiesComponent,
    AdminAmenitiesUpdateComponent,
    AdminCategoryComponent,
    AdminCategoryUpdateComponent,
    HomeFooterComponent,
    HomeMenuComponent,
    HomeToursComponent,
    HomeContactComponent,
    HomeToursDetailsComponent,
    UploadAttachmentMinComponent,
    AdminCompanyComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatSelectModule,
    MatSnackBarModule,
    MatButtonModule,
    MatListModule,
    MatToolbarModule,
    MatMenuModule,
    MatSidenavModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatPaginatorModule,
    NgbModule,
    ReactiveFormsModule,
    FormsModule,
    NgxGalleryModule,
    SessionModule.forRoot(),
    LoadingModule .forRoot(),
    SimpleNotificationsModule.forRoot(),
  ],
  providers: [
    MatDatepickerModule,
    {provide: DateAdapterMaterial, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
  bootstrap: [AppComponent],
  entryComponents:[
    AdminSlidersUpdateComponent,
    UploadImgMinComponent,
    UploadAttachmentMinComponent
  ]
})
export class AppModule { }
