import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SessionService } from '../../service/session/session.module';
import { LoadingService } from '../../directive/loading/loading.module';
import { NotificationsService, Options, NotificationAnimationType } from 'angular2-notifications';

@Component({
  selector: 'app-admin-company',
  templateUrl: './admin-company.component.html',
  styleUrls: ['./admin-company.component.css']
})
export class AdminCompanyComponent implements OnInit {
  companyModel = {
    about_us:'',
    section1:'',
    our_tours:'',
    video_title:'',
    video:''
  }
  object = JSON.parse(JSON.stringify(this.companyModel));

  notifyOptions:Options  = {
    timeOut:2000,
    showProgressBar:true,
    pauseOnHover: true,
    clickToClose:true,
    position:["top","center"],
    preventDuplicates:true,
    animate: NotificationAnimationType.Fade
  }

  constructor(private session: SessionService, public loadingService: LoadingService, public snackBar: MatSnackBar,private _notification: NotificationsService,) { }

  ngOnInit(): void {
    this.getCompany();
  }

  getCompany(){
    this.session.postRequest('company:get',{id:1}).subscribe((data:any)=>{
      this.object = JSON.parse(JSON.stringify(data.object));
      this.loadingService.hide();
    },
    (error)=>{
      this.loadingService.hide();
      console.log('Error: company:get',error)
    })
  }


  /**
   * funcion para guardar los datos de company
   */
  send(){
    this.loadingService.show(true,'Guardando registro...');
    if(this.object.video != ''){
      var str = this.object.video;
      var res = str.replace('/watch?v=', '/embed/')
      var embeddedUrl = res;
      console.log(embeddedUrl);
      this.object.video = embeddedUrl;
    }
    this.session.postRequest("company:update",this.object).subscribe(
      (data:any) => {
        this._notification.success('Información','Los datos se guardo correctamente',this.notifyOptions);
        this.loadingService.hide();
      },
      error => {
        console.log("Error: banner:update",error);
        this.loadingService.hide();
      }
    );
  }

}
