<app-menu-admin></app-menu-admin>
<div class="row justify-content-center mt-3">
  <div class="col-12 col-md-10">
    <div class="row justify-content-between">
      <div class="col-8">
        <h2 class="mb-2">Tours</h2>
      </div>
      <div class="col-3 text-right">
        <button mat-button color="primary" routerLink="/admin/tour/update/new"><mat-icon aria-hidden="false" aria-label="Nuevo">add_circle</mat-icon> Nuevo Tour</button>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12 col-sm-12 col-xs-12">
        <div class="card mt-4 mb-2">
          <div class="card-body" style="color: #666666;">
            <div class="row">
              <div class="col-12 table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th>Nombre</th>
                      <th>Categoria</th>
                      <th>Status</th>
                      <th>Idioma</th>
                      <th>Acciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let object of hotels">
                      <th>{{object.name}}</th>
                      <td>{{object.category}}</td>
                      <td>{{object.status == true ? 'Activo': 'Inactivo'}}</td>
                      <td>{{object.type}}</td>
                      <td>
                        <button mat-icon-button routerLink="/admin/tour/update/{{object.id}}"  placement="top" ngbTooltip="Editar registro">
                          <mat-icon>edit</mat-icon>
                        </button>
                        <button mat-icon-button class="naturaColor"  style="font-size: smaller;" matTooltip="Borrar" (click)="delete(object);" ><mat-icon>delete</mat-icon></button>

                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="row justify-content-center">
                  <div class="col-11 align-self-center">
                    <ngb-pagination [collectionSize]="hotelMin.total" [(page)]="hotelMin.currentPage" [pageSize]="hotelMin.perPage" [maxSize]="5" [rotate]="true" [ellipses]="false" [boundaryLinks]="true" (pageChange)="changePage($event);"></ngb-pagination>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
