import { Component, OnInit } from '@angular/core';
import { SessionService } from '../../service/session/session.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-menu-admin',
  templateUrl: './menu-admin.component.html',
  styleUrls: ['./menu-admin.component.css']
})
export class MenuAdminComponent implements OnInit {

  metadata = {
    language:'Español'
  }
  languageList = [
    'Español',
    'Inglés'
  ];
  constructor(public session: SessionService, private router: Router) {
  }

  ngOnInit() {
    if(this.session.getLanguage()){
      this.metadata.language = this.session.getLanguage();
    }
  }

  logout(){
    this.session.logout().then((data:any)=>{
      console.log(data);
      this.router.navigate(['/']);
    }).catch(e=>{
      this.router.navigate(['/']);
    })
  }

  /**función para cambiar el idioma
  */
  changeLenguaje(){
    console.log("language",this.metadata.language)
    this.session.setLanguage(this.metadata.language);
    this.router.navigate(['/admin']);
  }


}
