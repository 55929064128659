import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthenticationGuard } from './service/session/authentication.guard';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { AdminComponent } from './admin/admin.component';
import { AdminSlidersComponent } from './admin/admin-sliders/admin-sliders.component';
import { TourComponent } from './admin/tour/tour.component';
import { TourUpdateComponent } from './admin/tour/tour-update/tour-update.component';
import { AdminAmenitiesComponent } from './admin/admin-amenities/admin-amenities.component';
import { AdminCategoryComponent } from './admin/admin-category/admin-category.component';
import { HomeToursComponent } from './home/home-tours/home-tours.component';
import { HomeContactComponent} from './home/home-contact/home-contact.component';
import { HomeToursDetailsComponent } from './home/home-tours/home-tours-details/home-tours-details.component';
import { AdminCompanyComponent } from './admin/admin-company/admin-company.component';


const routes: Routes = [
  { path: 'home', component: HomeComponent},
  { path: '', pathMatch: 'full', redirectTo: 'home'},
  { path: 'login', component:LoginComponent },
  { path: 'admin', component:AdminComponent , canActivate:[AuthenticationGuard]},
  { path: 'admin/banner', component:AdminSlidersComponent  , canActivate:[AuthenticationGuard]},
  { path: 'admin/tour', component:TourComponent , canActivate:[AuthenticationGuard]},
  { path: 'admin/tour/update/:uuid', component:TourUpdateComponent , canActivate:[AuthenticationGuard]},
  { path: 'admin/amenities', component:AdminAmenitiesComponent  , canActivate:[AuthenticationGuard]},
  { path: 'admin/category', component:AdminCategoryComponent , canActivate:[AuthenticationGuard]},
  { path: 'home/tours', component:HomeToursComponent },
  { path: 'home/contact', component:HomeContactComponent },
  { path: 'home/toursdetails/:id', component:HomeToursDetailsComponent },
  { path: 'admin/company', component:AdminCompanyComponent },


];

@NgModule({
  imports: [RouterModule.forRoot(routes,{useHash:true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
