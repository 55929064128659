<app-home-menu></app-home-menu>
<div class="row">
  <div class="col-12">
    <ngb-carousel>
      <ng-template ngbSlide *ngFor="let banner of banners">
        <div class="picsum-img-wrapper">
          <img [src]="banner.image" alt="Random first slide" width="100%" style="height:30rem;object-fit:cover;">
        </div>
        <div class="carousel-caption ">
          <p class="text-dark font-robo mt-5 pt-5">Vive <span class="text-success">la mejor aventura </span>en un lugar mágico</p>
          <p class="text-primary mt-3 font-vuja">¡Atrévete a vivirlo!</p>
        </div>
      </ng-template>
    </ngb-carousel>
  </div>
</div>
<div class="row justify-content-center pt-3" style="background-color:#f3f3f3;">
  <div class="col-12 text-center">
    <h1 class="">TOURS</h1>
  </div>
  <div class="col-3">
    <select class="form-control form-control-sm" name="categorym" [(ngModel)]="tourMin.category" (change)="changeFilter();">
      <option value="">--TODOS LAS CATEGORIAS--</option>
      <option *ngFor="let object of categories" [value]="object.name">{{object.name}}</option>
    </select>
  </div>
  <div class="col-3">
    <select class="form-control form-control-sm" name="tangsd" [(ngModel)]="tourMin.tags" (change)="changeFilter();">
      <option value="">--TODOS LOS DESTINOS--</option>
    </select>
  </div>
  <div class="col-3">
    <select class="form-control form-control-sm" name="stratn"  [(ngModel)]="tourMin.start_number" (change)="changeFilter();">
      <option value="0">--TODAS LAS ESTRELLAS--</option>
      <option *ngFor="let object of starts" [value]="object.value">{{object.name}}</option>
    </select>
  </div>
  <div class="col-10 mt-3">
    <div class="mob-scroll">
      <div class="col-md-3 col-10" *ngFor="let tour of tours">
        <div class="card mx-auto">
          <img [src]="tour.image" class="card-img-top" style="height: 18rem;">
          <div class="card-body">
            <!-- <p class="card-title text-info text-center mb-0">CAPITAL MAYA</p> -->
            <h2 class="text-black fw-bold">{{tour.shortName}}</h2>
            <p class="card-text">{{tour.review}}</p>
            <div class="row justify-content-end mt-3">
              <div class="col-8" style="display: contents;">
                <button type="button" class="btn btn-more-tour" routerLink="/home/toursdetails/{{tour.id}}">Conozca <mat-icon class="material-icons-outlined">add_circle</mat-icon></button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row justify-content-center mt-3">
      <div class="col-md-3 col-12">
        <button type="button" class="btn btn-more" (click)="moreTours();">ver más</button>
      </div>
    </div>
  </div>
</div>
<app-home-footer></app-home-footer>
