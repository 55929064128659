<app-menu-admin></app-menu-admin>
<div class="row justify-content-center" style="margin-top: 50px;">
  <div class="col-12 col-md-10 cardTono">
    <div class="row justify-content-between">
      <div class="col-10">
        <h2 class="mb-2">Contenido de la pagína principal</h2>
      </div>
      <div class="col-10">
        <div class="row mt-5">
          <div class="col-12">
            <p>Ingresa los en el formulario.</p>
          </div>

          <mat-form-field class="col-12" appearance="outline">
            <mat-label>&nbsp;QUIENES SOMOS </mat-label>
            <textarea matInput placeholder="QUIENES SOMOS" name="about_us" [(ngModel)]="object.about_us" ></textarea>
          </mat-form-field>
          <mat-form-field class="col-12" appearance="outline">
            <mat-label>&nbsp;Cintillo Negro </mat-label>
            <textarea matInput placeholder="Cintillo Negro" name="section1" [(ngModel)]="object.section1" ></textarea>
          </mat-form-field>

          <mat-form-field class="col-12" appearance="outline">
            <mat-label>&nbsp;Texto Nuestros Tours </mat-label>
            <textarea matInput placeholder="Texto Nuestros Tours" name="our_tours" [(ngModel)]="object.our_tours" ></textarea>
          </mat-form-field>

          <mat-form-field class="col-12" appearance="outline">
            <mat-label>&nbsp;Titulo seccion video </mat-label>
            <input matInput placeholder="Titulo seccion video" name="video_title" [(ngModel)]="object.video_title" >
          </mat-form-field>

          <mat-form-field class="col-12" appearance="outline">
            <mat-label>&nbsp;video (URL) </mat-label>
            <input matInput placeholder="video (URL)" name="video" [(ngModel)]="object.video" >
          </mat-form-field>

        </div>
        <div class="row justify-content-center mt-4">
          <div class="col-12 col-md-6">
            <button mat-flat-button color="warn" style="width:100%;"  (click)="send();">Guardar</button>
          </div>
        </div>
      </div>
  </div>
</div>
