import { Input, HostListener, OnInit, Component, ChangeDetectionStrategy, ViewChild, ElementRef, EventEmitter, Output } from '@angular/core';
import { SessionService } from '../../service/session/session.service';
import { Observable } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LoadingService } from '../../directive/loading/loading.service';
export interface images {
  img:{
    file: string,
    type: string,
    name: string,
  },
  uploadted:boolean
}
@Component({
  selector: 'app-upload-attachment-min',
  templateUrl: './upload-attachment-min.component.html',
  styleUrls: ['./upload-attachment-min.component.css']
})
export class UploadAttachmentMinComponent implements OnInit {
  @Output() imageUploated = new EventEmitter();

  @ViewChild("imgGet", {static: false}) fileGet: ElementRef;
  @Input('mFile') metadata: images;

  @Input('uploadCode') uploadCode: string;

  constructor(protected session:SessionService, public snackBar: MatSnackBar,
    public loading:LoadingService
  ) {
  }

  ngOnInit() {}

  /**función para cargar una imagen
  */
  loadImg(){
    const fileUpload = this.fileGet.nativeElement;
    fileUpload.onchange = () => {
     this.metadata.img.name = fileUpload.files[0].name.replace(/ /g, "_");
     this.metadata.img.type = fileUpload.files[0].type;
     let file = fileUpload.files[0];
     this.metadata.img.file = file;
     console.log("FILE",this.metadata.img);
    };
    fileUpload.click();
  }

  /** función para subir una imagen al servidor
  */
  async uploadImg(){
    try{
      this.loading.show(true,"Espere un momento...")
      const formData = new FormData();
      formData.append('file', this.metadata.img.file, this.metadata.img.name);
      this.session.uploadFile(this.uploadCode,formData).subscribe(async(data:any)=>{
        this.metadata.uploadted = true;
        this.imageUploated.emit(true);
        this.snackBar.open('La imagen se subio con exito', 'Aceptar', {duration: 5000});
        this.loading.hide();
      },error=>{
        console.log(this.uploadCode,error);
        this.loading.hide();
      })
    }catch(e){
      console.log(e);
      this.loading.hide();
    }

  }

  /**función para quitar una iimagen
  */
  deleteImg(){
    this.metadata.img.file = '';
    this.metadata.img.name = '';
    this.metadata.uploadted = false;
  }

}
