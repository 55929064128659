
/**Arreglo que contiene todos los endpoints para acceso a al backend.
* *Code:
* @param code código interno para el manejo en frontEnd
* @param endpoint  endpoint para pedir al backEnd
* *
*/
let urls: Array<{ code: string, endpoint: string }> = [
  {
    code:'auth:login',
    endpoint:"auth/login"
  },{
    code: "auth:user",
    endpoint: "auth/user"
  },{
    code: "auth:logout",
    endpoint: "auth/logout"
  },{
    code: "auth:logout",
    endpoint: "auth/logout"
  },{
    code: "hotels:update",
    endpoint: "hotels/update"
  },{
    code: "hotels:all",
    endpoint: "hotels/all"
  },{
    code: "hotels:list",
    endpoint: "hotels/list"
  },{
    code: "event:list",
    endpoint: "event/list"
  },{
    code: "event:findAllWithPaginationAndStatus",
    endpoint: "event/findAllWithPaginationAndStatus"
  },{
    code: "events:all",
    endpoint: "events/all"
  },{
    code: "hotels:get",
    endpoint: "hotels/get"
  },{
    code: "amenities:get",
    endpoint: "amenities/get"
  },{
    code: "amenities:list",
    endpoint: "amenities/list"
  },{
    code: "objectAmenities:update",
    endpoint: "objectAmenities/update"
  },{
    code: "objectAmenities:findAllByObjectTypeAndObjectId",
    endpoint: "objectAmenities/findAllByObjectTypeAndObjectId"
  },{
    code: "objectAmenities:delete",
    endpoint: "objectAmenities/delete"
  },{
    code: "hotelGallery:update",
    endpoint: "hotelGallery/update"
  },{
    code: "hotelGallery:findAllByHotel",
    endpoint: "hotelGallery/findAllByHotel"
  },{
    code: "hotelGallery:get",
    endpoint: "hotelGallery/get"
  },{
    code: "hotelGallery:delete",
    endpoint: "hotelGallery/delete"
  },{
    code: "hotelGallery:findAllByHotelWithPicture",
    endpoint: "hotelGallery/findAllByHotelWithPicture"
  },{
    code: "affiliation:update",
    endpoint: "affiliation/update"
  },{
    code: "affiliation:get",
    endpoint: "affiliation/get"
  },{
    code: "affiliation:findAllByStatus",
    endpoint: "affiliation/findAllByStatus"
  },{
    code: "event:update",
    endpoint: "event/update"
  },{
    code: "event:get",
    endpoint: "event/get"
  },{
    code: "contact:update",
    endpoint: "contact/update"
  },{
    code: "contact:delete",
    endpoint: "contact/delete"
  },{
    code: "contact:findAllByObjectType",
    endpoint: "contact/findAllByObjectType"
  },{
    code: "provider:list",
    endpoint: "provider/list"
  },{
    code: "provider:get",
    endpoint: "provider/get"
  },{
    code: "provider:update",
    endpoint: "provider/update"
  },{
    code: "news:list",
    endpoint: "news/list"
  },{
    code: "news:update",
    endpoint: "news/update"
  },{
    code:"news:get",
    endpoint:"news/get"
  },{
    code:"news:all",
    endpoint:"news/all"
  },{
    code:"destinations:list",
    endpoint:"destinations/list"
  },{
    code:"routes:list",
    endpoint:"routes/list"
  },{
    code:"archaeological:list",
    endpoint:"archaeological/list"
  },{
    code:"unparalleled:list",
    endpoint:"unparalleled/list"
  },{
    code: "hotels:listByFiltering",
    endpoint: "hotels/listByFiltering"
  },{
    code: "news:listWithImage",
    endpoint: "news/listWithImage"
  },{
    code: "mail:contactUs",
    endpoint: "mail/contactUs"
  },{
    code: "mail:senNotificationAffiliation",
    endpoint: "mail/senNotificationAffiliation"
  },{
    code: "booking:update",
    endpoint: "booking/update"
  },{
    code: "vacant:list",
    endpoint: "vacant/list"
  },{
    code: "vacant:get",
    endpoint: "vacant/get"
  },{
    code: "vacant:update",
    endpoint: "vacant/update"
  },{
    code: "vacant:public",
    endpoint: "vacant/public"
  },{
    code: "vacant:findAllByPublic",
    endpoint: "vacant/findAllByPublic"
  },{
    code: "jobApplication:update",
    endpoint: "jobApplication/update"
  },{
    code: "jobApplication:findAllByVacant",
    endpoint: "jobApplication/findAllByVacant"
  },{
    code: "typeDestination:update",
    endpoint: "typeDestination/update"
  },{
    code: "typeDestination:list",
    endpoint: "typeDestination/list"
  },{
    code: "typeDestination:get",
    endpoint: "typeDestination/get"
  },{
    code: "destination:findAllByTypeDestinationWithuotImage",
    endpoint: "destination/findAllByTypeDestinationWithuotImage"
  },{
    code: "destination:update",
    endpoint: "destination/update"
  },{
    code: "destination:list",
    endpoint: "destination/list"
  },{
    code: "destination:get",
    endpoint: "destination/get"
  },{
    code: "destination:findAllByTypeDestination",
    endpoint: "destination/findAllByTypeDestination"
  },{
    code: "destinationGallery:update",
    endpoint: "destinationGallery/update"
  },{
    code: "destinationGallery:findAllByDestination",
    endpoint: "destinationGallery/findAllByDestination"
  },{
    code: "destinationGallery:get",
    endpoint: "destinationGallery/get"
  },{
    code: "destinationGallery:delete",
    endpoint: "destinationGallery/delete"
  },{
    code: "destinationGallery:findAllByDestinationWithPicture",
    endpoint: "destinationGallery/findAllByDestinationWithPicture"
  },{
    code: "booking:list",
    endpoint: "booking/list"
  },{
    code: "affiliation:downloadReportAffiliation",
    endpoint: "affiliation/downloadReportAffiliation"
  },{
    code: "upload:document",
    endpoint: "upload/document"
  },{
    code: "upload:get",
    endpoint: "upload/get"
  },{
    code: "upload:delete",
    endpoint: "upload/delete"
  },{
    code: "banner:get",
    endpoint: "banner/get"
  },{
    code: "banner:update",
    endpoint: "banner/update"
  },{
    code: "banner:delete",
    endpoint: "banner/delete"
  },{
    code: "banner:upload",
    endpoint: "banner/upload"
  },{
    code: "banner:list",
    endpoint: "banner/list"
  },{
    code: "hotel:upload",
    endpoint: "hotel/upload"
  },{
    code: "hotelGallery:upload",
    endpoint: "hotelGallery/upload"
  },{
    code: "amenities:update",
    endpoint: "amenities/update"
  },{
    code: "amenities:upload",
    endpoint: "amenities/upload"
  },{
    code: "amenities:delete",
    endpoint: "amenities/delete"
  },{
    code: "category:get",
    endpoint: "category/get"
  },{
    code: "category:update",
    endpoint: "category/update"
  },{
    code: "category:delete",
    endpoint: "category/delete"
  },{
    code: "category:upload",
    endpoint: "category/upload"
  },{
    code: "category:list",
    endpoint: "category/list"
  },{
    code: "hotels:findAllByTypeAndFav",
    endpoint: "hotels/findAllByTypeAndFav"
  },{
    code: "sender:update",
    endpoint: "sender/update"
  },{
    code: "sender:get",
    endpoint: "sender/get"
  },{
    code: "sender:delete",
    endpoint: "sender/delete"
  },{
    code: "sender:list",
    endpoint: "sender/list"
  },{
    code: "hotels:filteringAll",
    endpoint: "hotels/filteringAll"
  },{
    code: "hotels:delete",
    endpoint: "hotels/delete"
  },{
    code: "notinclude:update",
    endpoint: "notinclude/update"
  },{
    code: "notinclude:findAllByObjectTypeAndObjectId",
    endpoint: "notinclude/findAllByObjectTypeAndObjectId"
  },{
    code: "notinclude:delete",
    endpoint: "notinclude/delete"
  },{
    code:"hotelAttachment:update",
    endpoint: "hotelAttachment/update"
  },{
    code: "hotelAttachment:get",
    endpoint: "hotelAttachment/get"
  },{
    code: "hotelAttachment:delete",
    endpoint: "hotelAttachment/delete"
  },{
    code: "hotelAttachment:upload",
    endpoint: "hotelAttachment/upload"
  },{
    code: "company:list",
    endpoint: "company/list"
  },{
    code: "company:update",
    endpoint: "company/update"
  },{
    code: "company:delete",
    endpoint: "company/delete"
  },{
    code: "notinclude:findAllByObjectTypeAndObjectId",
    endpoint: "notinclude/findAllByObjectTypeAndObjectId"
  },{
    code: "hotelAttachment:findAllByHotelWithPicture",
    endpoint: "hotelAttachment/findAllByHotelWithPicture"
  },{
    code: "hotelAttachment:findAllByHotel",
    endpoint: "hotelAttachment/findAllByHotel"
  },{
    code: "company:get",
    endpoint: "company/get"
  }
];

export default urls;
