import { Component, OnInit } from '@angular/core';
import { SessionService } from '../service/session/session.service';
import { DomSanitizer } from '@angular/platform-browser';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  bannerMin = {
    max: 200,
    page: 1,
  }
  categoryMin = {
    max: 200,
    page: 1,
  }
  hotelMin = {
    max: 21,
    page: 1,
    perPage:0,
    currentPage:0,
    total:0,
    type:'Español',
    fav:true,
    status:true,
    filter: {
      name: "",
      value: ""
    },
  }
  banners = [];
  categories = [];
  toursFav = [];

  companyModel = {
    about_us:'',
    section1:'',
    our_tours:'',
    video_title:'',
    video:''
  }
  object = JSON.parse(JSON.stringify(this.companyModel));

  constructor(public session:SessionService, protected _sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.getBanners();
    this.getcategorys();
    this.getTourFav();
    this.getCompany();
  }

  /**función para obtener el listado de banners
  */
  getBanners(){
    this.session.postRequest("banner:list",this.bannerMin).subscribe((data:any)=>{
      this.banners = data.object.instanceList;
    },error=>{
      console.log("error:banner:list",error);
    })
  }

  getcategorys(){
    this.session.postRequest("category:list",this.categoryMin).subscribe((data:any)=>{
      this.categories = data.object.instanceList;
    },error=>{
      console.log("error:category:list",error);
    })
  }

  getTourFav(){
    this.session.postRequest("hotels:findAllByTypeAndFav",this.hotelMin).subscribe((data:any)=>{
      this.toursFav = data.object.instanceList;
    },error=>{
      console.log("error:hotels:findAllByTypeAndFav",error);
    })
  }

  getCompany(){
    this.session.postRequest('company:get',{id:1}).subscribe((data:any)=>{
      this.object = JSON.parse(JSON.stringify(data.object));
      if(this.object.video != ''){
        this.object.video = this._sanitizer.bypassSecurityTrustResourceUrl(this.object.video);
      }
    },
    (error)=>{
      console.log('Error: company:get',error)
    })
  }

}
