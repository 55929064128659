import { Component, OnInit } from '@angular/core';
import { SessionService } from '../../../service/session/session.service';
import { NgxGalleryOptions } from '@kolkov/ngx-gallery';
import { NgxGalleryImage } from '@kolkov/ngx-gallery';
import { NgxGalleryAnimation } from '@kolkov/ngx-gallery';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { LoadingService } from '../../../directive/loading/loading.service';

@Component({
  selector: 'app-home-tours-details',
  templateUrl: './home-tours-details.component.html',
  styleUrls: ['./home-tours-details.component.css']
})
export class HomeToursDetailsComponent implements OnInit {
  bannerMin = {
    max: 200,
    page: 1,
  }
  banners = [];

  galleryOptions: NgxGalleryOptions[] =
  [
    { "image": false, "height": "150px" },
    { "breakpoint": 500, "width": "100%" }
    ];

  galleryImages: NgxGalleryImage[];
  hotelModel={
    id:'',
    shortName:'',
    review:'',
    name:'',
    start_number:0,
    description:'',
    address:'',
    phone:'',
    email:'',
    web_site:'',
    category:'Hotel',
    image:'',
    name_image:'',
    status:true,
    type:'Español',
    fav:false,
    tags:''
  }
  object = JSON.parse(JSON.stringify(this.hotelModel));
  galleryList = [];
  hotelAmenitiesList = [];

  tours = [];
  tourMin = {
    max: 20,
    page: 1,
    perPage:0,
    currentPage:0,
    total:0,
    type:'Español',
    start_number:0,
    category:'',
    tags:'',
    filter: {
      name: "",
      value: ""
    },
  }
  metadata = {
    activeTab:0
  }
  amenitiesList = [];

  notincludeList = [];
  notincludeAttachementList = [];
  attachementList = [];

  constructor(public session:SessionService,
    private router:Router,
    public activatedRoute:ActivatedRoute, public loadding:LoadingService ) { }

async ngOnInit() {
    this.getBanners();
    this.getAmenities();
    await this.getHotels();
    this.activatedRoute.params.subscribe((params: Params)=>{
      if(params['id'] == 'new'){
      }else{
        this.activeTour(params['id'])
      }
    },error=>{
      console.log("activatedRoute:error",error);
    })
  }

  /**funcionalidad para activar un tour
  */
  activeTour(objectId){
    this.object.id = objectId;
    this.loadding.show(true,"Espere un momento...");
    this.session.postRequest("hotels:get",this.object).subscribe((data:any)=>{
      this.object = data.object;
      this.galleryList = [];
      this.hotelAmenitiesList = [];
      this.galleryImages = [];
      this.getHotelAmenities();
      this.findGallery();
      this.howIsActive();
      this.getNotInclude();
      this.findAttachments();
      this.loadding.hide();
    },error=>{
      console.log('hotels:get',error);
      this.loadding.hide();
    })
  }

  /**funcion para determinar que tour esta avtivo
  */
  howIsActive(){
    for(let i = 0; i < this.tours.length; i++){
      if(this.tours[i].id == this.object.id){
        this.metadata.activeTab = i;
      }
    }
  }

  /**función para obtener el listado de banners
  */
  getBanners(){
    this.session.postRequest("banner:list",this.bannerMin).subscribe((data:any)=>{
      this.banners = data.object.instanceList;
    },error=>{
      console.log("error:banner:list",error);
    })
  }

  findGallery(){
    this.session.postRequest("hotelGallery:findAllByHotel",{hotel:{id:this.object.id}}).subscribe((data:any)=>{
      this.galleryList = data.object.instanceList;
      let imgAux = [];
      for(let item of this.galleryList){
        imgAux.push({
          small:item.file,
          medium:item.file,
          big:item.file,
        });
      }
      this.galleryImages = imgAux;
    },error=>{
      console.log("error:hotelGallery:findAllByHotel",error);
    })
  }

  getHotelAmenities(){
    for(let j = 0; j < this.amenitiesList.length; j++){
      this.amenitiesList[j].selected = false;
    }
    this.session.postRequest("objectAmenities:findAllByObjectTypeAndObjectId",{objectType:'Hotel',objectId:this.object.id}).subscribe((data:any)=>{
      // console.log(data);
      this.hotelAmenitiesList = data.object.instanceList;
      for(let i = 0; i < this.hotelAmenitiesList.length; i++){
        for(let j = 0; j < this.amenitiesList.length; j++){
          if(this.hotelAmenitiesList[i].amenitiesId == this.amenitiesList[j].id){
            this.amenitiesList[j].selected = true;
          }
        }
      }
    },error=>{
      console.log("Error:objectAmenities:findAllByObjectTypeAndObjectId",error);
    })
  }

  getHotels() {
    return new Promise((resolve,reject)=>{
      this.session.postRequest("hotels:list", this.tourMin).subscribe((data: any) => {
        this.tours = this.tours.concat(data.object.instanceList);
        this.tourMin.currentPage = data.object.currentPage;
        this.tourMin.perPage = data.object.perPage;
        this.tourMin.total = data.object.total;
        resolve(true);
      }, error => {
        console.log("error", error);
        reject(error);
      })
    });

  }

  getAmenities(){
    this.session.postRequest("amenities:list",{}).subscribe((data:any)=>{
      // console.log(data);
      this.amenitiesList = data.object.instanceList;
      this.notincludeAttachementList = JSON.parse(JSON.stringify(data.object.instanceList));

    },error=>{
      console.log("error", error);
    })
  }

  /**funcionalidad para obtener no incluye
  */
  getNotInclude(){
    this.loadding.show(true,"Espere un momento...");
    this.session.postRequestAnonimus("notinclude:findAllByObjectTypeAndObjectId",{objectType:'Hotel',objectId:this.object.id}).subscribe((data:any)=>{
      // console.log(data);
      this.notincludeList = data.object.instanceList;
      for(let i = 0; i < this.notincludeList.length; i++){
        for(let j = 0; j < this.notincludeAttachementList.length; j++){
          if(this.notincludeList[i].amenitiesId == this.notincludeAttachementList[j].id){
            this.notincludeAttachementList[j].selected = true;
          }
        }
      }
      this.loadding.hide();
    },error=>{
      console.log("Error:notinclude:findAllByObjectTypeAndObjectId",error);
      this.loadding.hide();
    })
  }

  findAttachments(){
    this.loadding.show(true,"Espere un momento...");
    this.session.postRequestAnonimus("hotelAttachment:findAllByHotel",{hotel:{id:this.object.id}}).subscribe((data:any)=>{
      this.attachementList = data.object.instanceList;
      this.loadding.hide();
    },error=>{
      console.log("error:hotelAttachment:findAllByHotel",error);
      this.loadding.hide();
    })
  }
  
  downLoadFile(object){
    this.loadding.show(true,"Espere un momento...");
    this.session.postRequest("hotelAttachment:get",object).subscribe((data:any)=>{
      let elem = document.createElement('a');
      elem.href = data.object.file;
      elem.target = '_blank';
      elem.setAttribute('download', object.nameFile);
      document.body.appendChild(elem);
      elem.click();
      this.loadding.hide();
    },error=>{
      console.log("error:hotelAttachment:get",error);
      this.loadding.hide();
    })
  }

  changeTab(ev){
    console.log(ev);
    this.activeTour(this.tours[ev]);
  }

}
