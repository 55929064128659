import { Component, OnInit } from '@angular/core';
import { SessionService } from '../../service/session/session.service';
import { LoadingService } from '../../directive/loading/loading.service';
import { NotificationsService, Options, NotificationAnimationType } from 'angular2-notifications';

@Component({
  selector: 'app-home-contact',
  templateUrl: './home-contact.component.html',
  styleUrls: ['./home-contact.component.css']
})
export class HomeContactComponent implements OnInit {
  bannerMin = {
    max: 200,
    page: 1,
  }
  banners = [];

  sendModel = {
    id:'',
    name:'',
    lastname:'',
    asunto:'',
    email:'',
    phone:'',
    message:''
  }
  object = JSON.parse(JSON.stringify(this.sendModel));

  notifyOptions:Options  = {
    timeOut:20000,
    showProgressBar:true,
    pauseOnHover: true,
    clickToClose:true,
    position:["top","center"],
    preventDuplicates:true,
    animate: NotificationAnimationType.Fade
  }

  constructor(public session:SessionService,
    public loading:LoadingService,
    private _notification: NotificationsService
  ) {
   }

  ngOnInit(): void {
    this.getBanners();
  }

  getBanners(){
    this.session.postRequest("banner:list",this.bannerMin).subscribe((data:any)=>{
      this.banners = data.object.instanceList;
    },error=>{
      console.log("error:banner:list",error);
    })
  }

  send(){
    if(this.object.email != '' && this.object.phone != ''){
      this.loading.show(true,"Espere un momento...");
      this.session.postRequest("sender:update",this.object).subscribe((data:any)=>{
        this.object = JSON.parse(JSON.stringify(this.sendModel));
        this._notification.success('Información','Gracias, en breve nos pondremos en contacto contigo.',this.notifyOptions);
        this.loading.hide();
      },error=>{
        console.log("error:banner:list",error);
        this.loading.hide();
      })
    }else{
      this._notification.warn('Información','Complete la información para enviarnos un mensaje.',this.notifyOptions);
    }
  }

}
