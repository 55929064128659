import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, Validators, FormBuilder, FormGroup } from '@angular/forms';
import { SessionService } from '../service/session/session.module';
import { LoadingService } from '../directive/loading/loading.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationsService, Options, NotificationAnimationType } from 'angular2-notifications';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  object = {
    username:'',
    password:''
  }

  metadata = {
    company:{
      id:''
    },
    tableId:0
  }
  complexForm: FormGroup;

  notifyOptions:Options  = {
    timeOut:2000,
    showProgressBar:true,
    pauseOnHover: true,
    clickToClose:true,
    position:["top","center"],
    preventDuplicates:true,
    animate: NotificationAnimationType.Fade
  }

  constructor(
    public fb: FormBuilder,
    private session: SessionService,
    public loadingService:LoadingService,
    private _notification: NotificationsService,
    private router: Router,
  ) {
    this.complexForm = fb.group({
      // To add a validator, we must first convert the string value into an array. The first item in the array is the default value if any, then the next item in the array is the validator. Here we are adding a required validator meaning that the firstName attribute must have a value in it.
      'username': [null, Validators.required],
      'password': [null, Validators.compose([Validators.required])],
    });
  }

  ngOnInit(): void {
  }

  send() {
    this.loadingService.show(true,'Iniciando sesion');
    // Mandamos a invocar el servicio de login
    this.session.login(this.object).then((data:any)=> {
      console.log(data);
      if (data.transaction === 'ok') {
        this._notification.success('Información','Bienvenido',this.notifyOptions);
        this.router.navigate(['/admin']);
        this.loadingService.hide();
      } else {
        this.loadingService.hide();
        this._notification.error('Información','Usuario ó Contraseña incorrectos',this.notifyOptions);
        console.log('Usuario o Contraseña incorrectos')
      }
    }).catch((error)=>{
      this.loadingService.hide();
      this._notification.error('Información','Usuario ó Contraseña incorrectos',this.notifyOptions);
      console.log('Error: ', error);
    });
  }

}
