import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SessionService } from '../../../service/session/session.module';
import { LoadingService } from '../../../directive/loading/loading.module';
import { FormControl, Validators, FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-admin-amenities-update',
  templateUrl: './admin-amenities-update.component.html',
  styleUrls: ['./admin-amenities-update.component.css']
})
export class AdminAmenitiesUpdateComponent implements OnInit {


    @ViewChild("imgGet", {static: false}) fileGet: ElementRef;

    amenitiesModel = {
      id:'',
      icon:'',
      name:'',
      status:true
    }
    metadata = {
      ingles:false,
      img:{
        file: '',
        type: '',
        name: '',
      },
      uploadted:false
    }

    object = JSON.parse(JSON.stringify(this.amenitiesModel));
    constructor(public fb: FormBuilder, private session: SessionService, public loadingService: LoadingService, public snackBar: MatSnackBar, public dialogRef: MatDialogRef<AdminAmenitiesUpdateComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    }

    ngOnInit() {
      if(this.data != 'new') {
        this.loadingService.wait();
         // cargamos los datos de la BD
         this.session.postRequest('amenities:get',{id:this.data}).subscribe((data:any)=>{
           this.object = JSON.parse(JSON.stringify(data.object));
           this.metadata.img.name = this.object.icon.substring(this.object.icon.lastIndexOf("/")+1, this.object.icon.length);
           this.metadata.uploadted = true;
           this.loadingService.hide();
         },
         (error)=>{
           this.loadingService.hide();
           console.log('Error: amenities:get',error)
         })
      }
    }

    /**
     * funcion para guardar una unidades de medida
     */
    send(object){
      this.loadingService.show(true,'Guardando registro...');
      this.session.postRequest("amenities:update",object).subscribe(
        (data:any) => {
          this.snackBar.open('El fabricante se guardo correctamente', '', {
            duration: 8000
          });
          this.loadingService.hide();
          this.close(true);
        },
        error => {
          console.log("Error: amenities:update",error);
          this.loadingService.hide();
        }
      );
    }


    /**función para cargar una imagen
    */
    loadImg(){
      const fileUpload = this.fileGet.nativeElement;
      fileUpload.onchange = () => {
       this.metadata.img.name = fileUpload.files[0].name.replace(/ /g, "_");
       this.metadata.img.type = fileUpload.files[0].type;
       let file = fileUpload.files[0];
       console.log("FILE",fileUpload.files[0]);
       this.metadata.img.file = file;
       console.log("FILE",this.metadata.img);
      };
      fileUpload.click();
    }

    /** función para subir una imagen al servidor
    */
    async uploadImg(){
      try{
        this.loadingService.show(true,"Espere un momento...")
        const formData = new FormData();
        formData.append('file', this.metadata.img.file, this.metadata.img.name);
        this.session.uploadFile("amenities:upload",formData).subscribe(async(data:any)=>{
          this.object.icon = "http://127.0.0.1:8000/amenities/"+data.object.name;
          this.metadata.uploadted = true;
          this.snackBar.open('La imagen se subio con exito', 'Aceptar', {duration: 5000});
          this.loadingService.hide();
        },error=>{
          console.log("amenities:upload",error);
          this.loadingService.hide();
        })
      }catch(e){
        console.log(e);
        this.loadingService.hide();
      }

    }

    /**función para quitar una iimagen
    */
    deleteImg(){
      this.metadata.img.file = '';
      this.metadata.img.name = '';
      this.metadata.uploadted = false;
    }

    close(status){
      let object = {
        transaction:'',
        code:'',
        object:{}
      };
      if(status){
        object.transaction = 'ok';
        object.object = this.object;
      }else{
        object.transaction = 'bad';
        object.code = 'category:001';
      }
      this.dialogRef.close(object);
    }

}
